import * as React from 'react'
import Header from 'components/Header/header'
import { GalleryItem } from 'api/cockpit/types'
import { createSrcSet } from 'components/Image'

type Props = {
	with_search: boolean
	with_gradient: boolean
	search_title: string
	images: GalleryItem[]
	className?: string
	id?: string
}

const PageHeader = ({
	with_search = false,
	with_gradient = false,
	search_title = '',
	images,
	...props
}: Props) => {
	const randomImage = images[Math.floor(Math.random() * images.length)].path
	const coverDimensions = [
		{ width: 1920, height: 930 },
		{ width: 1680, height: 814 },
		{ width: 1440, height: 698 },
		{ width: 1280, height: 620 },
		{ width: 750, height: 861 },
		{ width: 375, height: 410 },
		{ width: 320, height: 350 },
	]
	const [coverImage, setCoverImage] = React.useState(randomImage)
	const [dimensions, setDimensions] = React.useState(
		createSrcSet(coverImage, coverDimensions)
	)

	React.useEffect(() => {
		setCoverImage(randomImage)
		setDimensions(createSrcSet(coverImage, coverDimensions))
	}, [])

	return (
		<Header
			showSearch={with_search}
			title={search_title}
			imageUrl={coverImage}
			srcSet={dimensions}
			imageGradient={with_gradient}
			imgHeight={480}
			{...props}
		/>
	)
}

export default PageHeader
