import * as React from 'react'
import styled from 'styled-components'

type Props = {
	title: string
	text: string
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
}

const Wrapper = styled.div`
	background: ${(props) => props.theme.colors.white};
	display: flex;
	align-items: flex-start;
	padding: 12px;
	box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.1);
`

const Text = styled.div`
	margin-left: 8px;
`

const IconWrapper = styled.span`
	i {
		color: ${(props) => props.theme.colors.grayAlmostDark};
		font-size: 20px;
	}
	svg {
		fill: ${(props) => props.theme.colors.grayAlmostDark};
	}
`

const InfoboxItem = (props: Props) => {
	const Icon = props.icon
	return (
		<Wrapper>
			<IconWrapper>
				<Icon height={24} width={30} />
			</IconWrapper>

			<Text>
				<p>
					<b>{props.title}</b>
				</p>
				<p>{props.text}</p>
			</Text>
		</Wrapper>
	)
}

export default InfoboxItem
