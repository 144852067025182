import * as React from 'react'
import Typography from 'components/Typography'
import * as Icon from 'components/Icon'
import SearchInput from './Input'
import styled from 'styled-components'
import { AutocompleteSuggestion } from 'api/types'
import Modal from 'components/Modal'

type Props = {
	entries: AutocompleteSuggestion[]
	onSelectEntry?: (entry: AutocompleteSuggestion) => void
	value: string
}

const pinColors = [
	'#FFF100',
	'#FF8C00',
	'#E81123',
	'#EC008C',
	'#68217A',
	'#00199F',
	'#00BCF2',
	'#00B294',
	'#009E49',
	'#BAD80A',
]

const Wrapper = styled.div`
	color: ${(props) => props.theme.colors.grayDark};
	background: ${(props) => props.theme.colors.grayLight};
	padding: 20px;
`

const List = styled.ul`
	width: 100%;
	padding: 0;
	margin: 0;
	list-style-type: none;
`

const ListItem = styled.li`
	text-align: left;
	cursor: pointer;
	display: flex;
	align-items: center;
	background: ${(props) => props.theme.colors.white};
	padding: 8px;
	border-radius: 8px;
	:hover {
		background: ${(props) => props.theme.colors.grayLight};
	}
	:not(:last-child) {
		margin-bottom: 6px;
	}
`

const MapMarkerIcon = styled(Icon.MapMarker)`
	font-size: 24px;
`

const RestaurantIcon = styled(Icon.ForkAndKnife)`
	font-size: 24px;
`

const ListItemAddress = styled(Typography)`
	margin-left: 8px;
`

const DefaultSearchResults = (props: Props) => {
	const { onSelectEntry } = props
	return (
		<Wrapper>
			<SearchInput value={props.value} entries={props.entries}>
				{(props) => (
					<List>
						{props.entries.map((entry, index) => (
							<ListItem
								key={entry.name}
								onClick={() => {
									onSelectEntry?.(entry)
									Modal.confirm(entry)
								}}
							>
								{entry.type === 'address' ? (
									<MapMarkerIcon
										style={{ color: pinColors[index % pinColors.length] }}
									/>
								) : (
									<RestaurantIcon />
								)}
								<ListItemAddress variant="body1" component="span">
									{entry.name}
								</ListItemAddress>
							</ListItem>
						))}
					</List>
				)}
			</SearchInput>
		</Wrapper>
	)
}

export default DefaultSearchResults
