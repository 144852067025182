import * as React from 'react'
import { useRouter } from 'next/router'
import api from 'api'
import { GeoAutoCompleteAddress, AutocompleteSuggestion } from 'api/types'
import Modal from 'components/Modal'
import GA from 'utils/analytics'
import {
	getActiveUserAddress,
	setActiveUserAddress,
} from 'utils/get-active-user-address'
import DefaultSearchResults from './default-search-results'
import { AuthContext } from 'providers/Authentication'
import logger from 'utils/logger'
import GetAddressModal from 'components/Restaurant/Page/GetAddressModal'
import { AddressContext } from 'providers/Address'
import { initGoogleMaps } from '@hungrydk/address-autocomplete'
import useAutocomplete from 'utils/autocomplete/useAutocomplete'
import SearchInput from './input-view'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { breakpoints } from 'styles/breakpoints'
import {
	List,
	ListEntry,
	PinIcon,
} from 'components/Shared/Modals/address-modal-view'

type Props = {
	onSuggestionModalShow?: (searchValue: string) => void
	onSuggestionModalSelect?: () => void
}

const Wrapper = styled.div`
	display: flex;
	width: 100%;
	box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.07), 0 2px 6px 0 rgba(0, 0, 0, 0.16);
`

const StyledSearchInput = styled(SearchInput)`
	flex: 1;
	margin: 0;
	color: ${(props) => props.theme.colors.grayMediumDark};

	input {
		padding-right: 48px; // More padding on small devices since we show location arrow
		border-radius: 0;

		@media ${breakpoints.tablet} {
			padding-right: 12px;
		}
	}
`

const SearchButton = styled.button`
	background: ${(props) => props.theme.colors.orange};
	border: none;
	cursor: pointer;
	width: 50px;
`

const SearchButtonImage = styled.img`
	width: 45%;
`

const SearchContainer = (props: Props) => {
	const { t } = useTranslation()
	const [searchValue, setSearchValue] = React.useState('')
	const { setActiveAddress, setActiveCity } = React.useContext(AddressContext)
	const [showSuggestions, setShowSuggestions] = React.useState(false)
	const router = useRouter()
	const { userData, isAuthenticating } = React.useContext(AuthContext)
	const [isPrefilled, setIsPrefilled] = React.useState(false)

	const blurInput = () => setShowSuggestions(false)
	const focusInput = () => setShowSuggestions(true)

	const navigateToSuggestion = async (suggestion: AutocompleteSuggestion) => {
		switch (suggestion.type) {
			case 'restaurant': {
				GA.sendSearchRestaurant(suggestion.name)
				// https://www.hungry.dk/aalborg-sv/burger-king-aalborg-sv/ --> /aalborg-sv/burger-king-aalborg-sv/
				const relativePath = suggestion.restaurant_menu_page_url.slice(
					suggestion.restaurant_menu_page_url.indexOf(suggestion.city_slug) - 1
				)

				router.push('/[citySlug]/[restaurantSlug]', relativePath, {
					shallow: true,
				})
				break
			}
			case 'city':
				GA.sendSearchCity(suggestion.zip_code)
				if (process.env.DEPLOYMENT_TOOL === 'CIRCLE_CI') {
					router.push('/city-list', `/${suggestion.city_slug}/`, {
						shallow: true,
					})
				} else {
					router.push('/[citySlug]', `/${suggestion.city_slug}/`, {
						shallow: true,
					})
				}
				break
			case 'address': {
				if (!suggestion.complete) return
				const address = await suggestion.addressLookup()
				GA.sendSearchAddress(address.postal_code)
				const geoAddress: GeoAutoCompleteAddress = {
					lat: address.latitude,
					lon: address.longitude,
					name: address.formatted_address,
					street_number: address.street_number,
					city_name: address.city,
					street_name: address.street_name,
					type: 'address',
					zipcode: address.postal_code,
					floor: address.floor,
					door: address.door,
				}
				setActiveAddress(geoAddress)
				setActiveCity(null)
				setActiveUserAddress(geoAddress)
				router.push(
					'/search/[latitude]/[...params]',
					`/search/${address.latitude}/${address.longitude}/`,
					{ shallow: true }
				)
				break
			}
			default:
				logger.error('Attempted to navigate to incomplete search suggestion', {
					suggestion,
				})
		}
	}

	const autocompleteProps = useAutocomplete({
		suggestionTypes: ['restaurant', 'address', 'city'],
		value: searchValue,
		onSuggestionSelect: navigateToSuggestion,
	})

	React.useEffect(() => {
		initGoogleMaps('AIzaSyAC-c2Mcg8lLeL7PFIOtz4kJhAtkQDPg50')
	}, [])

	React.useEffect(() => {
		// If field hasn't been prefilled we want to prefill it with the active_address if that is set
		// or with the last order addres if the customer has one
		if (isPrefilled) return

		const address = getActiveUserAddress()

		if (address) {
			// If user has an active address, prefill the search field with that
			setSearchValue(address.name)
			setIsPrefilled(true)
		} else if (!isAuthenticating && !userData) {
			// If the user is not logged in or in the process of doing so
			setIsPrefilled(true)
		} else if (userData && userData.user.last_order_address) {
			// If the user is logged in, use his last order address
			const lastOrderAddress = userData.user.last_order_address
			setSearchValue(
				`${lastOrderAddress.street_name} ${lastOrderAddress.street_number}, ${
					lastOrderAddress.etage ? `${lastOrderAddress.etage}, ` : ''
				}${lastOrderAddress.door ? `${lastOrderAddress.door}, ` : ''}${
					lastOrderAddress.zipcode
				} ${lastOrderAddress.city}`
			)
			setIsPrefilled(true)
		}
	}, [userData, isAuthenticating, isPrefilled])

	const onSearchButtonClick = async () => {
		if (!autocompleteProps.suggestions.length) return

		const suggestion = autocompleteProps.suggestions.find(
			(s) =>
				s.name.toLowerCase() ===
				autocompleteProps.inputProps.value.toLowerCase()
		)

		if (suggestion && (suggestion.type !== 'address' || suggestion.complete)) {
			navigateToSuggestion(suggestion)
			return
		}

		if (autocompleteProps.suggestions.length > 1) {
			if (props.onSuggestionModalShow) {
				props.onSuggestionModalShow(searchValue)
			}

			const result = await Modal.open<AutocompleteSuggestion>({
				html: (
					<DefaultSearchResults
						value={autocompleteProps.inputProps.value}
						entries={autocompleteProps.suggestions}
						onSelectEntry={props.onSuggestionModalSelect}
					/>
				),
				titleKey: 'search_modal_title',
			})

			if (!result.value) return
			setSearchValue(result.value.name)
			navigateToSuggestion(result.value)
		}
	}

	const getLocation = async (latitude: number, longitude: number) => {
		const data = await api.reverseGeoCode(
			latitude.toString(),
			longitude.toString()
		)
		const address = `${data.street_name} ${data.street_number}, ${data.zipcode} ${data.city}`
		setSearchValue(address)
		return address
	}

	const onGetLocationError = async () => {
		const result = await Modal.open<AutocompleteSuggestion>({
			html: (
				<GetAddressModal
					textKey="geolocation_error_modal_text"
					placeholderKey="geolocation_error_modal_placeholder"
				/>
			),
			customClass: {
				popup: 'modal-top-alignment',
			},
			titleKey: 'geolocation_error_modal_title',
			hasOverflowingContent: true,
		})
		if (result.value) {
			navigateToSuggestion(result.value)
		}
	}

	return (
		<Wrapper>
			<StyledSearchInput
				autocompleteProps={autocompleteProps}
				onFocus={focusInput}
				onBlur={blurInput}
				placeholder={t('search_street_placeholder')}
				onReceiveLocationCoords={getLocation}
				onGetLocationError={onGetLocationError}
			>
				{({ entries, selectedIndex, selectEntry }) => (
					<List $show={showSuggestions} data-testid="search-result-list">
						{entries.map((entry, index) => (
							<ListEntry
								data-testid="search-result-list-entry"
								selected={selectedIndex === index}
								key={entry.name}
								onMouseDown={(e) => {
									selectEntry(entry)
									if (entry.type === 'address' && !entry.complete) {
										// When clicking an incomplete address we don't want the blur event to be fired
										e.preventDefault()
									} else {
										// Clicking a restaurant, city or complete address
										navigateToSuggestion(entry)
									}
								}}
							>
								<PinIcon
									src="/assets/images/suggestion_address.png"
									alt="address pin"
								/>
								<span>{entry.name}</span>
							</ListEntry>
						))}
					</List>
				)}
			</StyledSearchInput>
			<SearchButton onClick={onSearchButtonClick}>
				<SearchButtonImage
					src="/assets/images/search_loop_white.png"
					alt="search loop"
				/>
			</SearchButton>
		</Wrapper>
	)
}

export default SearchContainer
