import * as React from 'react'
import * as Icon from 'components/Icon'
import LoadingIndicator from 'components/LoadingIndicator'
import GA from 'utils/analytics'
import logger from 'utils/logger'

type Props = {
	onSuccess: (latitude: number, longitude: number) => Promise<string>
	onError?: () => void
	className?: string
}

const LocationArrow = (props: Props) => {
	const [loading, setLoading] = React.useState(false)
	const [show, setShow] = React.useState(true)

	// Only show the location pin if user has not denied permissions
	React.useEffect(() => {
		// We might want to always display it in the future, but display a message
		// if the user has denied it.
		// Navigator permissions api is not supported by Safari
		if (window.navigator.permissions) {
			window.navigator.permissions
				.query({ name: 'geolocation' })
				.then((res) => setShow(res.state !== 'denied'))
				.catch((err) => logger.newrelicError(err))
		}
	}, [])

	const getLocation = () => {
		GA.sendSearchGetLocationClick()
		setLoading(true)
		window.navigator.geolocation.getCurrentPosition(
			(position) => {
				props
					.onSuccess(position.coords.latitude, position.coords.longitude)
					.then(() => GA.sendSearchGetLocationSuccess())
					.catch((err) => logger.newrelicError(err, { position }))
					.finally(() => setLoading(false))
			},
			async (err) => {
				// This can happen for various reasons, e.g: PERMISSION_DENIED, POSITION_UNAVAILABLE, TIMEOUT
				GA.sendSearchGetLocationError()
				logger.info('Get geolocation failed', {
					code: err.code,
					message: err.message,
				})
				setLoading(false)
				if (props.onError) {
					props.onError()
				}
			},
			{
				enableHighAccuracy: true,
				timeout: 5000,
			}
		)
	}

	if (!show) return null

	return (
		<div onClick={getLocation} className={props.className || ''}>
			{loading ? <LoadingIndicator size={20} /> : <Icon.LocationArrow />}
		</div>
	)
}

export default LocationArrow
