import * as React from 'react'
import { ResizeObserver } from '@juggle/resize-observer'
import useMeasure from 'react-use-measure'
import { useSpring, animated, config } from 'react-spring'

type Props = {
	children: React.ReactNode
	isOpen: boolean
}

const AnimateHeight = (props: Props) => {
	const [ref, { height }] = useMeasure({ polyfill: ResizeObserver })
	const [contentHeight, setContentHeight] = React.useState(() => {
		return props.isOpen ? height : 0
	})

	React.useEffect(() => {
		const updateHeight = () => setContentHeight(height)

		//Sets initial height
		updateHeight()

		//Adds resize event listener
		window.addEventListener('resize', updateHeight)

		// Clean-up
		return window.removeEventListener('resize', updateHeight)
	}, [height])

	const expand = useSpring({
		config: config.stiff,
		height: props.isOpen ? `${contentHeight}px` : '0px',
		opacity: props.isOpen ? 1 : 0,
		overflow: 'hidden',
	})

	return (
		<animated.div style={expand}>
			<div ref={ref}>{props.children}</div>
		</animated.div>
	)
}

export default AnimateHeight
