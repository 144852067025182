import * as React from 'react'
import styled from 'styled-components'
import { breakpoints } from 'styles/breakpoints'
import SearchBox from 'components/Image/search-box'
import SearchContainer from 'components/Header/Search/container'
import Typography from 'components/Typography'
import Picture, { Source } from 'components/Image/Picture'
import GA from 'utils/analytics'

type Props = {
	title: string
	imageUrl?: string
	showSearch: boolean
	srcSet: Source[]
	imageGradient?: boolean
	imgHeight?: number
}

const Wrapper = styled.div`
	position: relative;
`

const ImageWrapper = styled.div<{ showSearch: boolean }>`
	position: relative;
	overflow: hidden;
	${(props) => props.showSearch && `min-height: 260px;`}

	@media ${breakpoints.tablet} {
		max-height: 50vh;
	}
`

const HeaderImage = styled(Picture)<{ imgHeight: number | undefined }>`
	width: 100%;
	img {
		height: ${(props) => props.imgHeight || 480}px;
		object-fit: cover;
	}
`

const SearchBoxPosition = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
`

const SearchBoxWrapper = styled.div`
	padding: 20px;
	margin: 20px;
	background: ${(props) => props.theme.colors.white};
	border-radius: 5px;
	max-width: 600px;
	flex: 1;
`

const HeaderImageOverlay = styled.div<{ gradient: boolean }>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	${(props) =>
		props.gradient &&
		`
		background: linear-gradient(
			180deg,
			rgba(157, 192, 107, 0) 60%,
			${props.theme.colors.grayLight} 100%
		);
	`}
`

const SearchBoxTitle = styled(Typography)`
	white-space: break-spaces;

	@media ${breakpoints.tablet} {
		font-size: 36px;
		text-align: center;
	}
`

const Header = ({ imageGradient = false, srcSet, title, ...props }: Props) => {
	return (
		<Wrapper {...props}>
			<ImageWrapper showSearch={props.showSearch}>
				{props.imageUrl && (
					<HeaderImage
						imageUrl={props.imageUrl}
						srcSet={srcSet}
						alt="food"
						supportWebp={true}
						imgHeight={props.imgHeight}
						onError={(i) => (i.currentTarget.style.display = 'none')}
					/>
				)}
				<HeaderImageOverlay gradient={imageGradient} />
			</ImageWrapper>

			{props.showSearch && (
				<SearchBoxPosition>
					<SearchBoxWrapper>
						<SearchBoxTitle variant="h4" component="h1">
							{title}
						</SearchBoxTitle>
						<SearchBox>
							<SearchContainer
								onSuggestionModalShow={
									GA.sendGeoSearchShowSuggestionsModalFrontpage
								}
								onSuggestionModalSelect={
									GA.sendGeoSearchSuggestionModalSelectFrontpage
								}
							/>
						</SearchBox>
					</SearchBoxWrapper>
				</SearchBoxPosition>
			)}
		</Wrapper>
	)
}

export default Header
