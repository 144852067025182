import * as React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import Modal from 'components/Shared/Modals/modal'
import SearchInput from 'components/Header/Search/input-view'
import PrimaryButton from 'components/Shared/Buttons/Primary'
import { breakpoints } from 'styles/breakpoints'
import useAutocomplete from 'utils/autocomplete/useAutocomplete'
import { AutocompleteSuggestion } from 'api/types'

type Props = {
	children: React.ReactNode
}

const Wrapper = styled.div`
	padding: 50px 0;
`

const StyledSearchInput = styled(SearchInput)`
	width: 300px;
	height: 44px;
	margin: 30px auto 0;
	@media ${breakpoints.tablet} {
		width: 400px;
	}
`

export const List = styled.ul<{ $show: boolean }>`
	position: absolute;
	top: 0;
	width: 100%;
	box-sizing: border-box;
	list-style-type: none;
	padding: 0;
	margin: 0;
	border: 1px solid ${(props) => props.theme.colors.grayMediumLight};
	${(props) => (props.$show ? '' : `display: none;`)}
`

export const ListEntry = styled.li<{ selected: boolean }>`
	display: flex;
	align-items: center;
	background: green;
	padding: 8px;
	background: ${(props) => props.theme.colors.grayMediumLight};
	cursor: pointer;
	&:nth-child(even) {
		background: ${(props) => props.theme.colors.white};
	}
	&:hover {
		background: ${(props) => props.theme.colors.gray};
	}
	${(props) =>
		props.selected &&
		`
		background: ${props.theme.colors.gray} !important;
	`}
`

export const PinIcon = styled.img`
	margin-right: 8px;
`

const Button = styled(PrimaryButton)`
	margin-top: 85px;
`

const AddressModalBaseLayout = (props: Props) => {
	const autocompleteProps = useAutocomplete({
		suggestionTypes: ['address'],
		suggestionsLimit: 6,
	})
	const [showSearchResults, setShowSearchResults] = React.useState(false)
	const [selectedSuggestion, setSelectedSuggestion] = React.useState<
		AutocompleteSuggestion | undefined
	>(undefined)
	const blurInput = () => setShowSearchResults(false)
	const focusInput = () => setShowSearchResults(true)
	const { t } = useTranslation()

	React.useEffect(() => {
		const suggestion = autocompleteProps.suggestions.find(
			(suggestion) =>
				suggestion.type === 'address' &&
				suggestion.complete &&
				suggestion.name === autocompleteProps.inputProps.value
		)
		setSelectedSuggestion(suggestion)
	}, [autocompleteProps.suggestions, autocompleteProps.inputProps.value])

	const onChange = (e: React.FormEvent<HTMLInputElement>) => {
		autocompleteProps.inputProps.onChange(e)
		setShowSearchResults(true)
	}

	const onClick = () => {
		Modal.confirm(selectedSuggestion)
	}

	return (
		<Wrapper>
			{props.children}

			<StyledSearchInput
				autocompleteProps={autocompleteProps}
				onChange={onChange}
				placeholder={t('get_address_modal_placeholder')}
				onFocus={focusInput}
				onBlur={blurInput}
				withLocationIcon={true}
			>
				{({ entries, selectedIndex, selectEntry }) => (
					<List $show={showSearchResults}>
						{entries.map((entry, index) => (
							<ListEntry
								selected={selectedIndex === index}
								key={entry.name}
								onMouseDown={(e) => {
									selectEntry(entry)
									if (entry.type === 'address' && !entry.complete) {
										// When clicking an incomplete address we don't want the blur event to be fired
										e.preventDefault()
									}
								}}
							>
								<PinIcon
									src="/assets/images/suggestion_address.png"
									alt="address pin"
								/>
								<span>{entry.name}</span>
							</ListEntry>
						))}
					</List>
				)}
			</StyledSearchInput>
			<Button disabled={!selectedSuggestion} onClick={onClick}>
				{t('choose')}
			</Button>
		</Wrapper>
	)
}

export default AddressModalBaseLayout
