import * as React from 'react'
import styled from 'styled-components'

export type Source = {
	width: number
	url: string
}

type Props = {
	alt: string
	imageUrl: string
	className?: string
	srcSet: Source[]
	supportWebp: boolean
} & React.HTMLAttributes<HTMLPictureElement>

const StyledPicture = styled.picture`
	display: block;
	width: 100%;
	height: 100%;

	img {
		width: 100%;
	}
`

const Picture = ({ alt, imageUrl, srcSet, supportWebp, ...props }: Props) => {
	return (
		<StyledPicture {...props}>
			{supportWebp &&
				srcSet.map((src) => (
					<source
						key={src.url}
						media={`(min-width: ${src.width}px)`}
						srcSet={src.url + '.webp'}
						type="image/webp"
					/>
				))}
			{srcSet.map((src) => (
				<source
					key={src.url}
					media={`(min-width: ${src.width}px)`}
					srcSet={src.url}
				/>
			))}
			<img src={imageUrl} alt={alt} />
		</StyledPicture>
	)
}

export default Picture
