import * as React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
	margin: 20px 0;
`

type Props = {
	children: React.ReactNode
}

const SeachBox = (props: Props) => {
	return <Wrapper>{props.children}</Wrapper>
}

export default SeachBox
