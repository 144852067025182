import * as React from 'react'
import styled from 'styled-components'
import { breakpoints } from 'styles/breakpoints'
import * as Icon from 'components/Icon'
import * as SVG from 'components/SVG'
import InfoboxItem from './InfoboxItem'

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	grid-gap: 24px;
	margin-bottom: 24px;
	&:not(:first-child) {
		margin-top: 24px;
	}

	@media ${breakpoints.tablet} {
		grid-template-columns: repeat(4, 1fr);
	}
`

const Infobox = () => {
	return (
		<Wrapper>
			<InfoboxItem
				icon={
					Icon.ShoppingCart as React.FunctionComponent<
						React.SVGProps<SVGSVGElement>
					>
				}
				title="Vælg din burger"
				text="Fra restauranter i Aarhus"
			/>
			<InfoboxItem
				icon={SVG.WalletIcon}
				title="Betal sikkert"
				text="Med kort, mobil eller kontant."
			/>
			<InfoboxItem
				icon={SVG.DeliveryFeeIcon}
				title="Få maden leveret"
				text="Hurtigt og nemt. Du kan også selv hente."
			/>
			<InfoboxItem
				icon={SVG.CheckIcon}
				title="Optjen stempler"
				text="Og få et gratis bonusmåltid"
			/>
		</Wrapper>
	)
}

export default Infobox
