import api from 'api'
import { DefaultButton } from 'components/Buttons'
import Input from 'components/Forms/Input'
import Label from 'components/Forms/Label'
import Typography from 'components/Typography'
import * as React from 'react'
import * as yup from 'yup'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

type Props = {
	formId: string
	className?: string
	id?: string
}

const Wrapper = styled.div``

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`

const StyledInput = styled(Input)`
	margin-bottom: 12px;
`

const StyledLabel = styled(Label)`
	display: block;
`

const StyledButton = styled(DefaultButton)``

const SuccessText = styled(Typography)`
	color: ${(props) => props.theme.HighlightPrimary} !important;
`

const schema = yup.object({
	first_name: yup.string().required('field_required'),
	zipcode: yup.string().length(4).required('field_required'),
	email: yup.string().email('field_incorrect').required('field_required'),
})

const initialState = {
	zipcode: '',
	email: '',
	first_name: '',
}

const NewsletterForm = ({ formId, ...props }: Props) => {
	const [data, setData] = React.useState(initialState)
	const [isSubmitting, setIsSubmitting] = React.useState(false)
	const [isSubmitted, setIsSubmitted] = React.useState(false)
	const [errors, setErrors] = React.useState(initialState)
	const { t } = useTranslation()

	const onChange = (e: React.FormEvent<HTMLInputElement>) => {
		const { name, value } = e.currentTarget

		yup
			.reach(schema, name)
			.validate(value)
			.then(() => setErrors({ ...errors, [name]: '' }))
			.catch((err: any) => setErrors({ ...errors, [name]: err.message }))

		setData({
			...data,
			[e.currentTarget.name]: e.currentTarget.value,
		})
	}

	const submitForm = async () => {
		if (isSubmitted) return
		setIsSubmitting(true)

		try {
			await schema.validate(data)
			await api.trackForm(formId, data)

			setIsSubmitted(true)
			setErrors(initialState)
		} catch (e: any) {
			if (e.name === 'ValidationError') {
				setErrors({ ...initialState, [e.path]: e.message })
			}
		}

		setIsSubmitting(false)
	}

	return (
		<Wrapper {...props}>
			<StyledLabel>{t('newsletter_signup_zipcode_label')}</StyledLabel>
			<StyledInput
				name="zipcode"
				value={data.zipcode}
				onChange={onChange}
				errorLabel={t(errors.zipcode)}
			/>

			<StyledLabel>{t('newsletter_signup_mail_label')}</StyledLabel>
			<StyledInput
				name="email"
				value={data.email}
				onChange={onChange}
				errorLabel={t(errors.email)}
			/>

			<StyledLabel>{t('newsletter_signup_first_name_label')}</StyledLabel>
			<StyledInput
				name="first_name"
				value={data.first_name}
				onChange={onChange}
				errorLabel={t(errors.first_name)}
			/>

			{isSubmitted && (
				<SuccessText component="span" variant="body1">
					{t('newsletter_signup_success')}
				</SuccessText>
			)}

			<ButtonWrapper>
				<StyledButton onClick={() => submitForm()} disabled={isSubmitting}>
					{t('newsletter_signup_button')}
				</StyledButton>
			</ButtonWrapper>
		</Wrapper>
	)
}

export default NewsletterForm
