import * as React from 'react'
import { DefaultButton } from 'components/Buttons'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { generateSlug } from './slugGenerator'

type Props = {
	city: string
	category: string | null
}

const StyledButton = styled(DefaultButton)`
	&::first-letter {
		text-transform: capitalize;
	}
`

const Swimlane = (props: Props) => {
	const router = useRouter()
	const { t } = useTranslation()
	const slug = generateSlug(props.city, props.category)

	const onClick = () => router.push(slug, undefined, { shallow: true })

	if (!props.category)
		return (
			<StyledButton onClick={onClick}>
				{t('content_swimlane_button', { city: props.city })}
			</StyledButton>
		)

	return (
		<StyledButton onClick={onClick}>
			{t('content_swimlane_button_with_category', {
				category: props.category,
				city: props.city,
			})}
		</StyledButton>
	)
}

export default Swimlane
