import React from 'react'

const isClient = typeof window === 'object'

export const useWindowSize = () => {
	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined,
			isClient: isClient,
		}
	}

	const [windowSize, setWindowSize] = React.useState(getSize)

	React.useEffect(() => {
		if (!isClient) return

		function handleResize() {
			setWindowSize(getSize())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [])

	return windowSize
}
