import * as React from 'react'
import styled from 'styled-components'
import Typography from 'components/Typography'

type Props = {
	title: string
	description: string
	icon?: string
}

const Wrapper = styled.div`
	background: ${(props) => props.theme.colors.white};
	display: flex;
	align-items: flex-start;
	padding: 12px;
	margin: 6px 0;
	box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 2px 2px 4px -1px rgba(0, 0, 0, 0.1);
	flex: 1;
`

const Text = styled.div`
	margin-left: 8px;
`

const Icon = styled.img`
	object-fit: contain;
`

const Infobox = (props: Props) => {
	return (
		<Wrapper>
			{props.icon && <Icon src={props.icon} width={30} height={30} />}

			<Text>
				<Typography component="p" variant="subtitle1">
					{props.title}
				</Typography>
				<Typography component="p" variant="body1">
					{props.description}
				</Typography>
			</Text>
		</Wrapper>
	)
}

export default Infobox
