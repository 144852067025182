import * as React from 'react'
import { Component } from 'api/cockpit/types'
import styled from 'styled-components'
import * as Icon from 'components/Icon'
import Typography from 'components/Typography'
import AnimateHeight from 'components/Animations/SpringHeight'
import { useWindowSize } from 'utils/useWindowSize'
import { breakpointValues } from 'styles/breakpoints'
import CockpitComponent from '../Component'

type Props = {
	title: string
	content: Component[]
	className?: string
	id?: string
}

const Wrapper = styled.div`
	width: 100%;
`

const TitleWrapper = styled.div`
	border-top: 1px solid ${(props) => props.theme.colors.gray};
	padding: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`

const ContentWrapper = styled.div`
	padding: 10px;
`

const Title = styled(Typography)``

const MobileAccordion = ({ title, content = [], ...props }: Props) => {
	const [isOpen, setIsOpen] = React.useState(false)
	const { width } = useWindowSize()

	const onTitleClick = () => {
		setIsOpen(!isOpen)
	}

	return (
		<Wrapper {...props}>
			{!width ||
				(width > breakpointValues.tablet && (
					<>
						<Title component="h4" variant="subtitle1">
							{title}
						</Title>
						{content.map((cont, index) => (
							<CockpitComponent key={index} component={cont} />
						))}
					</>
				))}
			{width && width < breakpointValues.tablet && (
				<>
					<TitleWrapper onClick={() => onTitleClick()}>
						<Title component="h3" variant="subtitle1">
							{title}
						</Title>
						{isOpen ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
					</TitleWrapper>
					<AnimateHeight isOpen={isOpen}>
						<ContentWrapper>
							{content.map((cont, index) => (
								<CockpitComponent key={index} component={cont} />
							))}
						</ContentWrapper>
					</AnimateHeight>
				</>
			)}
		</Wrapper>
	)
}

export default MobileAccordion
