import * as React from 'react'
import { GeoAutoCompleteAddress } from 'api/types'
import { getActiveUserAddress } from 'utils/get-active-user-address'

type Props = {
	children: React.ReactNode
}

export type ActiveCity = {
	name: string
	latitude: number
	longitude: number
	slug: string
}

type AddressContext = {
	activeAddress: GeoAutoCompleteAddress | null
	setActiveAddress: (address: GeoAutoCompleteAddress) => void
	activeCity: ActiveCity | null
	setActiveCity: (city: ActiveCity | null) => void
}

export const AddressContext = React.createContext<AddressContext>({
	activeAddress: null,
	setActiveAddress: () => null,
	activeCity: null,
	setActiveCity: () => null,
})

const AddressProvider = (props: Props) => {
	const [
		activeAddress,
		setActiveAddress,
	] = React.useState<GeoAutoCompleteAddress | null>(getActiveUserAddress)
	const [activeCity, setActiveCity] = React.useState<ActiveCity | null>(null)

	return (
		<AddressContext.Provider
			value={{
				activeAddress,
				setActiveAddress,
				activeCity,
				setActiveCity,
			}}
		>
			{props.children}
		</AddressContext.Provider>
	)
}

export default AddressProvider
