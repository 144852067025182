import * as React from 'react'
import styled from 'styled-components'
import { breakpoints } from 'styles/breakpoints'

const BadgeWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 20px;
	flex-wrap: wrap;

	@media ${breakpoints.tablet} {
		flex: 1;
	}
`

const AppImage = styled.img`
	height: 50px;
	margin: 4px;
`

const AppDownloadButtons = () => {
	return (
		<BadgeWrapper>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://play.google.com/store/apps/details?id=hungry.dk.android"
			>
				<AppImage src="/assets/images/google-play-badge-dk.png" />
			</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://apps.apple.com/dk/app/hungry-dk-bestil-takeaway/id666759664"
			>
				<AppImage src="/assets/images/appstore-badge-dk.svg" />
			</a>
			<a
				target="_blank"
				rel="noopener noreferrer"
				href="https://appgallery.huawei.com/#/app/C101849727"
			>
				<AppImage src="/assets/images/huawie_app_gallery.png" />
			</a>
		</BadgeWrapper>
	)
}

export default AppDownloadButtons
