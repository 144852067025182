import * as React from 'react'
import { Component } from 'api/cockpit/types'
import styled from 'styled-components'
import { breakpoints } from 'styles/breakpoints'
import InfoboxDeprecated from './Infobox'
import Infobox from './Infobox/infobox-new'
import Swimlane from './Swimlane'
import { DefaultButton } from 'components/Buttons'
import Head from 'next/head'
import DOMPurify from 'isomorphic-dompurify'
import Gallery from './Gallery'
import FAQ from './FAQ'
import MobileAccordion from './MobileAccordion'
import PageHeader from './PageHeader'
import SellingPoint from './SellingPoint'
import Card from './Card'
import DownloadApp from './DownloadApp'
import NewsletterForm from './NewsletterForm'

type Props = {
	component: Component
}

const Grid = styled.div`
	display: flex;
	flex-direction: column;

	@media ${breakpoints.tablet} {
		flex-direction: row;
	}
`

const Column = styled.div`
	flex: 1;
	display: flex;

	@media ${breakpoints.tablet} {
		&:not(:last-child) {
			margin-right: 12px;
		}
	}
`

const SwimlaneWrapper = styled.span`
	margin-right: 24px;
`

const Section = styled.div``

const Text = styled.div`
	a {
		display: inline-block;
	}
`

const Image = styled.img``

export const findComponent = (
	contentArray: (Component & { children?: any })[],
	name: string
): Component | null => {
	const found = contentArray.find((item) => item.component === name)
	if (found) {
		return found
	} else {
		for (let i = 0; i < contentArray.length && !found; i++) {
			if (contentArray[i].children) {
				return findComponent(contentArray[i].children, name) // eslint-disable-line
			}
		}
		return null
	}
}

const getComponent = (item: Component) => {
	switch (item.component) {
		case 'grid':
			return (
				<Grid className={item.settings.class} id={item.settings.id}>
					{item.columns.map((column, index) => (
						<Column
							key={index}
							className={column.settings.class}
							id={column.settings.id}
						>
							{column.children.map((comp, index) => (
								<CockpitComponent key={index} component={comp} />
							))}
						</Column>
					))}
				</Grid>
			)
		case 'section':
			return (
				<Section className={item.settings.class} id={item.settings.id}>
					{item.children.map((item, index) => (
						<CockpitComponent key={index} component={item} />
					))}
				</Section>
			)
		case 'text':
			return (
				<Text
					className={item.settings.class}
					id={item.settings.id}
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(item.settings.text),
					}}
				/>
			)
		case 'button':
			return (
				<DefaultButton
					onClick={() => (window.location.href = item.settings.url)}
					className={item.settings.class}
					id={item.settings.id}
				>
					{item.settings.text}
				</DefaultButton>
			)
		case 'image':
			return item.settings.image?.path ? (
				<Image
					className={item.settings.class}
					id={item.settings.id}
					src={item.settings.image?.path}
					width={item.settings.width || undefined}
					height={item.settings.height || undefined}
					alt="image"
				/>
			) : null
		case 'swimlane':
			return (
				<SwimlaneWrapper>
					<Swimlane
						city={item.settings.city}
						category={item.settings.category}
					/>
				</SwimlaneWrapper>
			)
		case 'infoboxitem':
			return (
				<Infobox
					title={item.settings.title}
					description={item.settings.description}
					icon={item.settings.icon?.path || ''}
				/>
			)
		case 'Infobox':
			return <InfoboxDeprecated />
		case 'heading': {
			const Component = item.settings.tag || 'h3'
			return <Component>{item.settings.text}</Component>
		}
		case 'html':
			return (
				<Text
					className={item.settings.class}
					id={item.settings.id}
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(item.settings.html),
					}}
				/>
			)
		case 'gallery':
			return (
				<Gallery
					className={item.settings.class}
					id={item.settings.id}
					name={item.name}
					items={item.settings.gallery}
				/>
			)
		case 'faq':
			return (
				<FAQ
					className={item.settings.class}
					id={item.settings.id}
					entries={item.settings.entries}
				/>
			)
		case 'mobile_accordion':
			return (
				<MobileAccordion
					className={item.settings.class}
					id={item.settings.id}
					content={item.settings.content}
					title={item.settings.title}
				/>
			)
		case 'structureddata':
			return (
				<Head>
					<script
						type="application/ld+json"
						dangerouslySetInnerHTML={{
							__html: DOMPurify.sanitize(
								JSON.stringify(item.settings.data, null, 2)
							),
						}}
					/>
				</Head>
			)
		case 'page_header':
			return (
				<PageHeader
					with_search={item.settings.with_search}
					with_gradient={item.settings.with_gradient}
					search_title={item.settings.search_title || ''}
					images={item.settings.images}
					className={item.settings.class}
					id={item.settings.id}
				/>
			)
		case 'selling_point':
			return (
				<SellingPoint
					title={item.settings.title}
					subtitle={item.settings.subtitle}
					text={item.settings.text}
					className={item.settings.class}
					id={item.settings.id}
				/>
			)
		case 'card':
			return (
				<Card
					image={item.settings.image}
					title={item.settings.title}
					text={item.settings.text}
					link={item.settings.link}
					className={item.settings.class}
					id={item.settings.id}
				/>
			)
		case 'download_app':
			return (
				<DownloadApp
					text={item.settings.text}
					image={item.settings.image}
					className={item.settings.class}
					id={item.settings.id}
				/>
			)
		case 'newsletter_form':
			return (
				<NewsletterForm
					id={item.settings.id}
					className={item.settings.class}
					formId={item.settings.form_id}
				/>
			)
		default:
			// @ts-ignore
			console.error('Missing component for', item.component)
			return null
	}
}

const CockpitComponent = (props: Props) => {
	return (
		<>
			{props.component.settings.style && (
				<Head>
					<style>{props.component.settings.style}</style>
				</Head>
			)}
			{getComponent(props.component)}
		</>
	)
}

export default CockpitComponent
