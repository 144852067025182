import * as React from 'react'
import Input from 'components/Forms/Input'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AutocompleteSuggestion } from 'api/types'
import useAutocomplete from 'utils/autocomplete/useAutocomplete'
import LocationArrow from '../location-arrow'
import { breakpoints } from 'styles/breakpoints'
import * as SVG from 'components/SVG'

export type ChildrenProps = {
	entries: AutocompleteSuggestion[]
	selectEntry: (entry: AutocompleteSuggestion) => void
	selectedIndex: number
}

type Props = {
	className?: string
	children: (childProps: ChildrenProps) => React.ReactElement
	autocompleteProps: ReturnType<typeof useAutocomplete>
	onReceiveLocationCoords?: (
		latitude: number,
		longitude: number
	) => Promise<string>
	onGetLocationError?: () => void
	withLocationIcon?: boolean
} & Omit<
	React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	>,
	'ref'
>

const Wrapper = styled.div`
	margin-bottom: 12px;
	position: relative;
`

const LocationIcon = styled(SVG.Location)`
	position: absolute;
	top: 10px;
	left: 15px;
`

const StyledInput = styled(Input)<{ withIcon: boolean }>`
	${(props) =>
		props.withIcon &&
		`input {
		padding-left: 40px;
	}`}
`

const ErrorMessage = styled.p`
	text-align: left;
	position: absolute;
	margin-top: 4px;
`

const Children = styled.div`
	position: relative;
`

const StyledLocationArrow = styled(LocationArrow)`
	position: absolute;
	height: 100%;
	top: 0;
	right: 0;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;

	@media ${breakpoints.tablet} {
		display: none !important;
	}
`

const SearchInput = ({
	className,
	children,
	autocompleteProps,
	onReceiveLocationCoords,
	onGetLocationError,
	withLocationIcon = false,
	...props
}: Props) => {
	const {
		inputProps,
		loading,
		suggestions,
		error,
		selectedIndex,
		selectSuggestion,
	} = autocompleteProps
	const { t } = useTranslation()

	return (
		<Wrapper className={className || ''}>
			<StyledInput
				{...inputProps}
				{...props}
				withIcon={withLocationIcon}
				loading={loading}
				data-testid="search-input"
			/>
			{withLocationIcon && <LocationIcon />}
			{onReceiveLocationCoords && onGetLocationError && !loading && (
				<StyledLocationArrow
					onSuccess={onReceiveLocationCoords}
					onError={onGetLocationError}
				/>
			)}
			{!loading && error ? (
				<ErrorMessage className="form-input-error-message">
					{t('search_modal_error')}
				</ErrorMessage>
			) : null}
			<Children>
				{children({
					entries: suggestions,
					selectEntry: selectSuggestion,
					selectedIndex,
				})}
			</Children>
		</Wrapper>
	)
}

export default SearchInput
