import * as React from 'react'
import styled from 'styled-components'
import { breakpoints, breakpointValues } from 'styles/breakpoints'
import Typography from 'components/Typography'

type Props = {
	title: string
	subtitle: string
	text: string
	className?: string
	id?: string
}

const SellingPointWrapper = styled.article`
	margin-bottom: 24px;
	@media ${breakpoints.tablet} {
		border-left: 2px solid ${(props) => props.theme.colors.gray};
		padding-left: 24px;
	}
	@media (max-width: ${breakpointValues.tablet}px) {
		width: 100%;
		text-align: center;
	}
`

const Title = styled(Typography)`
	font-size: 30px;
	color: ${(props) => props.theme.colors.greenDark};
`

const SellingPoint = ({ title, subtitle, text, ...props }: Props) => (
	<SellingPointWrapper {...props}>
		<Title variant="h4" component="h3">
			{title}
		</Title>
		<Typography variant="subtitle1" component="h3">
			{subtitle}
		</Typography>
		<Typography variant="body1" component="p">
			{text}
		</Typography>
	</SellingPointWrapper>
)

export default SellingPoint
