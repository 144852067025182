import * as React from 'react'
import styled from 'styled-components'
import { breakpoints } from 'styles/breakpoints'
import AppDownloadButtons from './AppDownloadButtons'
import DOMPurify from 'isomorphic-dompurify'

type Props = {
	text: string
	className?: string
	id?: string
	image: {
		path: string
		meta: {
			title: string
		}
	} | null
}

const Wrapper = styled.div`
	background: ${(props) => props.theme.colors.white};
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	box-sizing: border-box;

	@media ${breakpoints.tablet} {
		padding: 0;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: flex-start;
	}
`

const DownloadAppText = styled.div`
	color: ${(props) => props.theme.colors.grayDark};
	white-space: break-spaces;
	padding: 20px;
`

const DownloadAppImage = styled.img`
	width: 100%;

	@media ${breakpoints.tablet} {
		width: 50%;
	}
`

const Content = styled.div`
	width: 100%;
	@media ${breakpoints.tablet} {
		width: 50%;
	}
`

const DownloadApp = ({ text = '', image = null, ...props }: Props) => {
	return (
		<Wrapper {...props}>
			<DownloadAppImage
				src={image?.path || '/assets/images/app-download-frontpage.png'}
				alt={image?.meta?.title || 'download app'}
			/>

			<Content>
				<DownloadAppText
					dangerouslySetInnerHTML={{
						__html: DOMPurify.sanitize(text),
					}}
				></DownloadAppText>
				<AppDownloadButtons />
			</Content>
		</Wrapper>
	)
}

export default DownloadApp
