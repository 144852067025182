import * as React from 'react'
import { GalleryItem } from 'api/cockpit/types'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { CloseButton } from 'components/Modal/base'
import { breakpoints } from 'styles/breakpoints'
import Typography from 'components/Typography'
import { resizedUrl } from 'components/Image'

type Props = {
	name: string
	items: GalleryItem[]
	className?: string
	id?: string
}

const Wrapper = styled.div`
	margin-top: 28px;
`

const Grid = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: minmax(auto, 200px);
	grid-gap: 12px;

	@media ${breakpoints.tabletS} {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media ${breakpoints.tablet} {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@media ${breakpoints.laptop} {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
`

const Thumbnail = styled.div`
	cursor: pointer;
	background: black;
	padding: 4px;
	border-radius: 4px;
	display: flex;
	justify-content: center;

	& > img {
		max-height: 200px;
		object-fit: contain;
	}
`

const SlidePreview = styled(Thumbnail)`
	background: transparent;
	margin: 12px 0 0;
	padding: 8px;
	max-height: 100px;
	min-width: 100px;
	width: 100px;

	& > img {
		width: 100%;
	}
`

const ModalBackdrop = styled(animated.div)<{ disablePointerEvents: boolean }>`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.7);
	display: flex;
	justify-content: center;
	align-items: center;

	${(props) =>
		props.disablePointerEvents &&
		`
    pointer-events: none;
  `}
`

const Modal = styled(animated.div)`
	position: relative;
	height: 90vh;
	max-height: 800px;
	width: 95vw;
	max-width: 1200px;
`

const ModalThumbnails = styled.div`
	display: flex;
	overflow-x: scroll;
	padding-bottom: 12px;
`

const ModalSlide = styled.div`
	width: 100%;
	height: 80%;
	background: black;
	display: flex;
	justify-content: center;
	padding: 40px 0;

	& > img {
		width: 100%;
		object-fit: contain;
	}
`

const StyledCloseButton = styled(CloseButton)`
	color: ${(props) => props.theme.colors.white};
`

const Gallery = ({ name, items, ...props }: Props) => {
	const [currentSlide, setCurrentSlide] = React.useState(0)
	const [showModal, setShowModal] = React.useState(false)

	const backdrop = useSpring({
		opacity: showModal ? 1 : 0,
	})

	const modal = useSpring({
		top: showModal ? 0 : 15,
	})

	const closeModal = () => {
		document.body.style.overflow = 'unset'
		setShowModal(false)
	}

	const openModal = () => {
		document.body.style.overflow = 'hidden'
		setShowModal(true)
	}

	const onThumbnailClick = (index: number) => {
		openModal()
		setCurrentSlide(index)
	}

	React.useEffect(() => {
		const onKeyPress = (e: KeyboardEvent) => {
			switch (e.keyCode) {
				// Left arrow
				case 37:
					setCurrentSlide(
						(currentSlide) => (currentSlide - 1 + items.length) % items.length
					)
					break
				// Right arrow
				case 39:
					setCurrentSlide((currentSlide) => (currentSlide + 1) % items.length)
					break
				// Escape
				case 27:
					closeModal()
					break
			}
		}

		document.addEventListener('keydown', onKeyPress, false)
		return () => {
			document.removeEventListener('keydown', onKeyPress, false)
		}
	}, [])

	return (
		<Wrapper {...props}>
			<Typography component="h4" variant="h5">
				{name}
			</Typography>
			<Grid>
				{items.map((item, index) => (
					<div key={item.path}>
						<Thumbnail onClick={() => onThumbnailClick(index)}>
							<img src={resizedUrl(item.path, { width: 150, height: 150 })} />
						</Thumbnail>
					</div>
				))}
				<ModalBackdrop style={backdrop} disablePointerEvents={!showModal}>
					<Modal style={modal}>
						<StyledCloseButton onClick={closeModal} />
						<ModalSlide>
							<img src={items[currentSlide].path} />
						</ModalSlide>
						<ModalThumbnails>
							{items.map((item, index) => (
								<SlidePreview
									key={item.path}
									onClick={() => setCurrentSlide(index)}
								>
									<img
										src={resizedUrl(item.path, { width: 100, height: 100 })}
									/>
								</SlidePreview>
							))}
						</ModalThumbnails>
					</Modal>
				</ModalBackdrop>
			</Grid>
		</Wrapper>
	)
}

export default Gallery
