import { AutocompleteSuggestion } from 'api/types'
import * as React from 'react'
import logger from 'utils/logger'
import useDebounce from 'utils/useDebounce'
import { getAutocompleteSuggestions } from '.'

type Props = {
	value?: string
	initialSuggestions?: AutocompleteSuggestion[]
	suggestionTypes?: AutocompleteSuggestion['type'][] // Types of suggestions to search for
	onSuggestionSelect?: (suggestion: AutocompleteSuggestion) => void
	suggestionsLimit?: number
}

const useAutocomplete = (props: Props) => {
	const [value, setValue] = React.useState(props.value || '')
	const [loading, setLoading] = React.useState(false)
	const [suggestions, setSuggestions] = React.useState(
		props.initialSuggestions || []
	)
	const [error, setError] = React.useState(false)
	const debouncedValue = useDebounce(value, 500)
	const [selectedIndex, setSelectedIndex] = React.useState(-1)
	const [isInputDirty, setIsInputDirty] = React.useState(false)

	const onChange = (e: React.FormEvent<HTMLInputElement>) => {
		setIsInputDirty(true)
		setValue(e.currentTarget.value)
	}

	React.useEffect(() => {
		if (props.value) {
			setValue(props.value)
		}
	}, [props.value])

	React.useEffect(() => {
		if (debouncedValue.trim()) {
			setLoading(true)
			getAutocompleteSuggestions(
				debouncedValue,
				props.suggestionTypes,
				props.suggestionsLimit
			)
				.then((suggestions) => setSuggestions(suggestions))
				.then(() => setError(false))
				.catch((err) => {
					setError(true)
					logger.error(err, { searchValue: debouncedValue })
				})
				.finally(() => setLoading(false))
		}
	}, [debouncedValue, JSON.stringify(props.suggestionTypes)])

	const selectSuggestion = (suggestion: AutocompleteSuggestion) => {
		setValue(suggestion.name)
	}

	const onKeyDown = React.useCallback(
		(e: React.KeyboardEvent<HTMLInputElement>) => {
			switch (e.key) {
				case 'ArrowDown':
					setSelectedIndex((selectedIndex + 1) % suggestions.length)
					break
				case 'ArrowUp':
					setSelectedIndex(
						selectedIndex - 1 >= 0 ? selectedIndex - 1 : suggestions.length - 1
					)
					break
				case 'Escape':
					// Prevent the modal from being closed
					e.stopPropagation()
					break
				case 'Enter': {
					const suggestion = suggestions[selectedIndex]
					if (!suggestion) return
					selectSuggestion(suggestion)
					props.onSuggestionSelect?.(suggestion)
					break
				}
			}
		},
		[suggestions, setSelectedIndex, selectedIndex, selectSuggestion]
	)

	return {
		inputProps: {
			value,
			onChange,
			autoComplete: 'hungrysearch',
			onKeyDown,
		},
		loading: loading && isInputDirty,
		selectSuggestion,
		suggestions,
		selectedIndex,
		error,
	}
}

export default useAutocomplete
