function generateSlug(city: string, category: string | null) {
	const citySlug = city
		.toLowerCase()
		.split(' ')
		.join('-')
		.split('æ')
		.join('ae')
		.split('ø')
		.join('oe')
		.split('å')
		.join('a')
		.replace(/[^a-zA-Z0-9-]+/g, '')

	if (!category) return `/${citySlug}`
	return `/${citySlug}/${category}`
}

export { generateSlug }
