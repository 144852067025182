import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from 'components/Typography'
import styled from 'styled-components'
import SearchInput from 'components/Header/Search/Input'
import * as SVG from 'components/SVG'
import Modal from 'components/Modal'

type Props = {
	onSelectEntry?: () => void
	textKey: string
	placeholderKey: string
}

const Wrapper = styled.div`
	background: ${(props) => props.theme.colors.grayLight};
	text-align: left;
	padding: 24px 24px;

	h1.h4 {
		display: none;
	}
`

const StyledSearchInput = styled(SearchInput)`
	margin: 16px 0;
`

const List = styled.ul`
	position: absolute;
	top: 0;
	width: 100%;
	box-sizing: border-box;
	list-style-type: none;
	padding: 0;
	margin: 0;
	border: 1px solid ${(props) => props.theme.colors.grayMediumLight};
`

const ListEntry = styled.li<{ selected: boolean }>`
	display: flex;
	align-items: center;
	background: green;
	padding: 8px;
	background: ${(props) => props.theme.colors.grayMediumLight};
	cursor: pointer;
	&:nth-child(even) {
		background: ${(props) => props.theme.colors.white};
	}
	&:hover {
		background: ${(props) => props.theme.colors.gray};
	}
	${(props) =>
		props.selected &&
		`
		background: ${props.theme.colors.gray} !important;
	`}
`

const PinIcon = styled.img`
	margin-right: 8px;
`

const ConfidentialityWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 24px;
`

const LockIcon = styled(SVG.Lock)`
	margin-right: 8px;
`

const GetAddressModal = (props: Props) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			<Typography component="p" variant="body1">
				{t(props.textKey)}
			</Typography>
			<StyledSearchInput
				placeholder={t(props.placeholderKey)}
				suggestionTypes={['address']}
			>
				{({ entries, selectedIndex, selectEntry }) => (
					<List>
						{entries.map((entry, index) => (
							<ListEntry
								selected={selectedIndex === index}
								key={entry.name}
								onClick={() => {
									selectEntry(entry)
									if (entry.type === 'address' && entry.complete) {
										Modal.close(entry)
									}
								}}
							>
								<PinIcon
									src="/assets/images/suggestion_address.png"
									alt="address pin"
								/>
								<span>{entry.name}</span>
							</ListEntry>
						))}
					</List>
				)}
			</StyledSearchInput>
			<ConfidentialityWrapper>
				<LockIcon />
				<Typography component="span" variant="body2">
					{t('restaurant_get_address_confidentiality_notice')}
				</Typography>
			</ConfidentialityWrapper>
		</Wrapper>
	)
}

export default GetAddressModal
