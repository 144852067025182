import * as React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import Typography from 'components/Typography'
import { GalleryItem } from 'api/cockpit/types'

type Props = {
	image: GalleryItem
	title: string
	text: string
	link: string
	className?: string
	id?: string
}

const CardItem = styled.div`
	border-radius: 4px;
	overflow: hidden;
	background: ${(props) => props.theme.colors.white};
	white-space: pre-line;
`
const CardImage = styled.img`
	width: 100%;
`

const CardContent = styled.article`
	padding: 12px;
`

const CardTitle = styled(Typography)`
	margin-bottom: 4px;
`

const CardText = styled(Typography)`
	white-space: pre-line;
`

const Card = ({ image, title = '', text = '', link, ...props }: Props) => (
	<CardItem {...props}>
		<Link href={link || ''}>
			<a>
				<CardImage src={image.path} alt={image.meta?.title || ''} />
			</a>
		</Link>
		<CardContent>
			<CardTitle variant="h6" component="h2">
				{title}
			</CardTitle>
			<CardText variant="body2" component="p">
				{text}
			</CardText>
		</CardContent>
	</CardItem>
)

export default Card
