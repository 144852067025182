import * as React from 'react'
import { FAQEntry } from 'api/cockpit/types'
import DOMPurify from 'isomorphic-dompurify'
import styled from 'styled-components'
import * as Icon from 'components/Icon'
import Typography from 'components/Typography'
import AnimateHeight from 'components/Animations/SpringHeight'

type Props = {
	entries: FAQEntry[]
	className?: string
	id?: string
}

const Wrapper = styled.div`
	border-radius: ${(props) => props.theme.buttonBorderRadius};
	border: 1px solid rgb(238, 238, 238);
`

const Entry = styled.div`
	padding: 20px 12px;

	&:not(:last-child) {
		border-bottom: 1px solid rgb(238, 238, 238);
	}
`

const QuestionHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`

const Question = styled(Typography)`
	margin-right: 12px;
`

const Answer = styled(Typography)`
	margin-right: 26px;
`

const FAQ = ({ entries, ...props }: Props) => {
	const [openIndex, setOpenIndex] = React.useState(-1)

	const onQuestionClick = (index: number) => {
		if (openIndex !== index) {
			setOpenIndex(index)
		} else {
			setOpenIndex(-1)
		}
	}

	return (
		<Wrapper {...props}>
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{
					__html: DOMPurify.sanitize(
						JSON.stringify(
							{
								'@context': 'https://schema.org',
								'@type': 'FAQPage',
								mainEntity: entries.map((entry) => ({
									'@type': 'Question',
									name: entry.value.question,
									acceptedAnswer: {
										'@type': 'Answer',
										text: entry.value.answer,
									},
								})),
							},
							null,
							2
						)
					),
				}}
			/>

			{entries.map((entry, index) => (
				<Entry key={entry.value.question}>
					<QuestionHeader onClick={() => onQuestionClick(index)}>
						<Question component="h3" variant="subtitle1">
							{entry.value.question}
						</Question>
						{openIndex === index ? <Icon.ChevronUp /> : <Icon.ChevronDown />}
					</QuestionHeader>
					<AnimateHeight isOpen={index === openIndex}>
						<Answer component="p" variant="body1">
							{entry.value.answer}
						</Answer>
					</AnimateHeight>
				</Entry>
			))}
		</Wrapper>
	)
}

export default FAQ
