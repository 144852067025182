import { Cookies } from 'utils/cookies'
import { GeoAutoCompleteAddress } from 'api/types'
import { Address } from '@hungrydk/address-autocomplete'

/** Returns the current active address if user has one */
export function getActiveUserAddress(): GeoAutoCompleteAddress | null {
	if (typeof window === 'undefined') return null
	const cookie = Cookies.getCookieValue('active_address')
	if (!cookie) return null
	return JSON.parse(cookie) as GeoAutoCompleteAddress
}

export function setActiveUserAddress(address: GeoAutoCompleteAddress) {
	if (typeof window === 'undefined') return null
	Cookies.setCookie('active_address', JSON.stringify(address), 0)
}

export function addressAutocopmleteToGeoAutocomplete(address: Address) {
	const geoAddress: GeoAutoCompleteAddress = {
		lat: address.latitude,
		lon: address.longitude,
		name: address.formatted_address,
		street_number: address.street_number,
		city_name: address.city,
		street_name: address.street_name,
		type: 'address',
		zipcode: address.postal_code,
		floor: address.floor,
		door: address.door,
	}

	return geoAddress
}
