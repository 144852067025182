import { API, AutocompleteSuggestion } from 'api/types'
import api from 'api'
import { AddressAutocomplete } from '@hungrydk/address-autocomplete'

/**
 * Returns an array of auto complete suggestions. Will query all types specified when the search string contains 3 or more characters. If the 'city' type is provided, it will search for it if the search string contains of only numbers.
 * @param searchValue String being searched for
 * @param types Type of and order of suggestions returned. Defaults to ['restaurant', 'address', 'city']
 * @param limit Maximum amount of suggestions returned
 */
export function getAutocompleteSuggestions(
	searchValue: string,
	types: AutocompleteSuggestion['type'][] = ['restaurant', 'address', 'city'],
	limit = 10
): Promise<API.GetAutocompleteSuggestions> {
	const query = searchValue.trimStart()
	if (query.length < 3) {
		// If search string is less than 3 characters and consists of only numbers, we query the city api only.
		if (types.includes('city') && /^\d+$/.test(query)) {
			return api
				.getAutocompleteCity(query)
				.then((suggestions) => suggestions.slice(0, limit))
		}
		return Promise.resolve([])
	}

	const promises: Promise<AutocompleteSuggestion[]>[] = []

	types.forEach((type) => {
		promises.push(
			(() => {
				switch (type) {
					case 'restaurant':
						return api.getAutocompleteRestaurant(query)
					case 'address':
						return AddressAutocomplete(query, 'dawa', 'google')
					case 'city':
						return api.getAutocompleteCity(query)
				}
			})()
		)
	})

	return Promise.allSettled(promises).then((types) =>
		types
			.reduce((suggestions: AutocompleteSuggestion[], curr) => {
				if (curr.status === 'rejected' || curr.value.length === 0)
					return suggestions

				switch (curr.value[0].type) {
					case 'restaurant':
						return suggestions.concat(curr.value)
					case 'address':
						return suggestions.concat(curr.value)
					case 'city':
						return suggestions
							.slice(0, limit - curr.value.length)
							.concat(curr.value)
				}
			}, [])
			.slice(0, limit)
	)
}
