import * as React from 'react'
import { AutocompleteSuggestion } from 'api/types'
import useAutocomplete from 'utils/autocomplete/useAutocomplete'
import InputView from './input-view'
import { ChildrenProps } from './input-view'

type Props = {
	value?: string
	entries?: AutocompleteSuggestion[]
	className?: string
	children: (childProps: ChildrenProps) => React.ReactElement
	suggestionTypes?: AutocompleteSuggestion['type'][] // Types of suggestions to search for
} & Omit<
	React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	>,
	'ref'
>

const SearchInput = ({
	value,
	entries: initialEntries,
	suggestionTypes,
	children,
	...props
}: Props) => {
	const autocompleteProps = useAutocomplete({
		value,
		initialSuggestions: initialEntries,
		suggestionTypes,
	})

	return (
		<InputView {...props} autocompleteProps={autocompleteProps}>
			{children}
		</InputView>
	)
}

export default SearchInput
